<template>
    <v-app>
        <Navbar :isMobile="$store.getters.isMobile" :productsInCart="productsInCart" />

        <v-container>
            <v-row>
                <v-col>
                    <h2>Seu Carrinho</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <div class="products">
                        <v-row class="product" v-for="(p, index) in products" :key="index">
                            <v-col cols="4" md="3" class="image">
                                <img :src="p.product.images[0].src" style="width: 100%;" alt="">
                            </v-col>
                            <v-col class="desc">
                                <v-row>
                                    <v-col cols="9" class="name">
                                        <h3>{{ p.product.name }} ({{ p.variantSelected.value }})</h3>
                                    </v-col>
                                    <v-col class="delete">
                                        <img src="../assets/icons/delete.svg" @click="removeProduct(index)" alt="">
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" md="8" class="price">
                                        <h3>{{ parseFloat(p.sale_price).toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL' }) }}</h3>
                                    </v-col>
                                    <v-col class="qnt">
                                        <v-row class="input-qnt">
                                            <v-col class="remove" @click="p.qnt <= 1 ? p.qnt = 1 : p.qnt--"
                                                style="padding: 4px;">
                                                <img src="../assets/icons/minus.svg" alt="">
                                            </v-col>
                                            <v-col class="qnt" style="padding: 4px;">
                                                {{ p.qnt }}
                                            </v-col>
                                            <v-col class="add" @click="p.qnt++" style="padding: 4px;">
                                                <img src="../assets/icons/plus.svg" alt="">
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col>
                    <div class="summary">
                        <v-row>
                            <v-col>
                                <h2>Resumo</h2>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h3>Subtotal</h3>
                            </v-col>
                            <v-col style="text-align: right;">
                                <h3>{{ (products.reduce((acc, p) => acc + (p.sale_price * p.qnt),
                                    0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h3>Frete</h3>
                            </v-col>
                            <v-col style="text-align: right;">
                                <h3>{{ shipping.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <hr>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h3>Total</h3>
                            </v-col>
                            <v-col style="text-align: right;">
                                <h3>{{ (((products.reduce((acc, p) => acc + (p.sale_price * p.qnt), 0)) +
                                    shipping)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h3>
                            </v-col>
                        </v-row>
                        <v-row @click="shippingSelected = 'RETIRAR'; shipping = parseFloat(4.99)"
                            :class="{ 'shipping-card': true, 'selected': shippingSelected == 'RETIRAR' }">
                            <v-col>RETIRAR NA LOJA</v-col>
                            <v-col>
                                <h3>{{ `R$ 4,99` }}</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <input type="text" class="input-default" placeholder="CEP" v-model="cep"
                                    v-mask="'#####-###'">
                            </v-col>
                            <v-col>
                                <button class="btn-default" @click="calculateShipping" v-if="!loading">Calcular
                                    Frete</button>
                                <button class="btn-default" disabled v-else>Calculando...</button>
                            </v-col>
                        </v-row>
                        <v-row v-if="listShipping != null"
                            @click="shippingSelected = 'PAC'; shipping = parseFloat(listShipping.valorpac.replace(',', '.'))"
                            :class="{ 'shipping-card': true, 'selected': shippingSelected == 'PAC' }">
                            <v-col>PAC</v-col>
                            <v-col>
                                <h3>{{ `R$ ${listShipping.valorpac}` }}</h3>
                                <span> Prazo: {{ listShipping.prazopac }} Dias</span>
                            </v-col>
                        </v-row>
                        <v-row v-if="listShipping != null"
                            @click="shippingSelected = 'SEDEX'; shipping = parseFloat(listShipping.valorsedex.replace(',', '.'))"
                            :class="{ 'shipping-card': true, 'selected': shippingSelected == 'SEDEX' }">
                            <v-col>SEDEX</v-col>
                            <v-col>
                                <h3>{{ `R$ ${listShipping.valorsedex}` }}</h3>
                                <span> Prazo: {{ listShipping.prazosedex }} Dias</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <button :class="{ 'btn-default': true, 'disabled': btnDisabled }"
                                    :disabled="btnDisabled" @click="goCheckout()">Finalizar Compra</button>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" color="error" top right>
            {{ snackbarMessage }}
        </v-snackbar>

        <Footer :isMobile="$store.getters.isMobile" />
    </v-app>
</template>
<script>
import Notice from '@/components/Notice.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios'
import { mask } from 'vue-the-mask'

export default {
    name: 'CartPage',
    directives: { mask },
    metaInfo() {
        return {
            title: `${this.storeData.name} - Carrinho`
        }
    },
    components: {
        Notice,
        Navbar,
        Footer,
        FontAwesomeIcon
    },
    data() {
        return {
            products: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [],
            btnDisabled: true,
            loading: false,
            shippingSelected: null,
            shipping: 0,
            cep: '',
            listShipping: null,
            productsInCart: 0,
            snackbar: false,
            snackbarMessage: '',
            snackbarTimeout: 3000
        }
    },
    async mounted() {
        window.fbq('track', 'ViewContent');
        await this.verifyQntCart()
    },
    methods: {
        verifyQntCart() {
            this.productsInCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).length : 0
            this.productsInCart > 0 ? this.btnDisabled = false : this.btnDisabled = true
        },
        removeProduct(index) {
            this.products.splice(index, 1);
            localStorage.setItem('cart', JSON.stringify(this.products));
            this.verifyQntCart();
        },
        async calculateShipping() {
            const cepValid = this.cep.match(/^\d{5}-?\d{3}$/);
            if (!cepValid) {
                this.showSnackbar('CEP inválido');
                return;
            }

            try {
                this.loading = true
                let response = await axios.get(`https://www.cepcerto.com/ws/json-frete/03407040/${this.cep.replace(/\D/g, '')}/2000/10/50/41/2267b617c37153c7614df022a2177df9e4a9c3d1`)
                if (response.data && !response.data.error) {
                    this.listShipping = response.data
                } else {
                    this.showSnackbar('CEP inválido');
                }
                this.loading = false
            } catch (error) {
                this.loading = false
                this.showSnackbar('Erro ao calcular o frete');
                console.log(error)
            }
        },
        showSnackbar(message) {
            this.snackbarMessage = message;
            this.snackbar = true;
        },
        async goCheckout() {
            window.fbq('track', 'InitiateCheckout');
            gtag('event', 'conversion', {
                'send_to': 'AW-16744777079/dIIBCO3Y2t4ZEPeKxLA-',
                'value': parseFloat(((this.products.reduce((acc, p) => acc + (p.sale_price * p.qnt), 0)) + this.shipping).toFixed(2)),
                'currency': 'BRL',
            })
            if (this.shippingSelected == null) {
                alert('Selecione um frete')
                return
            }

            if (this.shippingSelected == 'PAC') {
                let shippingData = {
                    name: 'PAC',
                    cep: this.cep,
                    price: parseFloat(this.listShipping.valorpac.replace(',', '.')),
                    deadline: this.listShipping.prazopac
                }
                localStorage.setItem('shipping', JSON.stringify(shippingData))
            }

            if (this.shippingSelected == 'SEDEX') {
                let shippingData = {
                    name: 'SEDEX',
                    cep: this.cep,
                    price: parseFloat(this.listShipping.valorsedex.replace(',', '.')),
                    deadline: this.listShipping.prazosedex
                }
                localStorage.setItem('shipping', JSON.stringify(shippingData))
            }

            if (this.shippingSelected == 'RETIRAR') {
                let shippingData = {
                    name: 'RETIRAR',
                    price: parseFloat(4.99)
                }
                localStorage.setItem('shipping', JSON.stringify(shippingData))
            }

            this.$router.push(this.$route.params.storeSlug == undefined ? `/payment` : `/${this.$route.params.storeSlug}/payment`)
        }
    }
}
</script>

<style scoped>
* {
    font-family: "Exo", sans-serif;
}

::placeholder {
    color: #00000066;
    opacity: 1;
    /* Firefox */
}

h2 {
    font-size: 2.5rem;
    font-weight: 700;
}

.btn-default {
    width: 100%;
    background-color: #000;
    color: #fff;
    border-radius: 62px;
    padding: 10px;
}

.btn-default.disabled {
    background-color: #00000066;
}

.input-default {
    width: 100%;
    padding: 10px;
    padding-left: 15px;
    border: 1px solid #F0F0F0;
    background-color: #F0F0F0;
    border-radius: 50px;
}

.input-qnt {
    border: 1px solid #F0F0F0;
    background-color: #F0F0F0;
    border-radius: 50px;
    margin-right: 10px;
}

.input-qnt .remove img,
.input-qnt .add img {
    width: 20px;
    cursor: pointer;
}

.input-qnt .qnt {
    text-align: center;
    font-size: 1.5rem;
}

.input-qnt .add img {
    float: right;
    margin-top: 8px;
    margin-right: 10px;
}

.input-qnt .remove img {
    float: left;
    margin-top: 8px;
    margin-left: 10px;
}

.products {
    border-radius: 20px;
    border: 1px solid #0000001A;
    padding: 20px;
}

.products .product {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #0000001A;
}

.products .product .price {
    padding-top: 10px;
}

.products .product .delete {
    text-align: right;
    cursor: pointer;
}

.summary {
    border-radius: 20px;
    border: 1px solid #0000001A;
    padding: 20px;
}

.shipping-card {
    cursor: pointer;
    padding: 10px;
    margin-top: 10px;
}

.shipping-card:hover {
    background-color: #00000033;
}

.shipping-card.selected {
    background-color: #00000033;
}

.v-snackbar {
    right: 0 !important;
    top: 0 !important;

    @media (min-width: 960px) {
        left: auto !important;
    }
}
</style>
